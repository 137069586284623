.swiper {
    width: 100%;
    height: 100%;
  }
 
  .swiper-wrapper{
    width: 100vw;
    height: auto;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  
